
import { Box, Heading, Text, VStack, Image } from '@chakra-ui/react';
import HeroSection from '../components/HeroSection';
import { motion } from "framer-motion";

const MotionBox = motion(Box);

function InfoAbout() {
  return (
    <Box>
      {/* Sección de introducción con HeroSection */}
      <HeroSection
        title="¿Qué es GeoTMM?"
        description= ""
      />

      {/* Primera Sección: ¿Qué es la aplicación? */}
      <MotionBox py={10} px={6} bg="white" flex="1" p={5} initial={{ opacity: 0, y: 50 }} whileInView={{ opacity: 1, y: 0 }} transition={{ duration: 0.8 }}>
        <Heading textAlign="center" mb={6} size="lg">
          ¿Qué es la Aplicación?
        </Heading>
        <VStack spacing={8} maxW="800px" mx="auto" textAlign="center">
          <Text fontSize="lg">
            GeoTMM es una aplicación innovadora que busca optimizar el uso de datos GPS mediante la asignación precisa de rutas a calles. La plataforma está diseñada para aquellos que necesitan hacer un seguimiento detallado de desplazamientos y obtener datos precisos sobre rutas recorridas, tanto en entornos urbanos como rurales.
          </Text>
          <Image src="/images/ScreenshotApp.png" alt="App Screenshot" borderRadius="md" />
        </VStack>
      </MotionBox>

      {/* Segunda Sección: ¿Por qué surge la aplicación? */}
      <MotionBox py={10} px={6} flex="1" p={5} initial={{ opacity: 0, y: 50 }} whileInView={{ opacity: 1, y: 0 }} transition={{ duration: 0.8 }}>
        <Heading textAlign="center" mb={6} size="lg">
          ¿Por qué surge GeoTMM?
        </Heading>
        <VStack spacing={8} maxW="800px" mx="auto" textAlign="center">
          <Text fontSize="lg">
            GeoTMM surge ante la necesidad de mejorar la precisión y eficiencia en la recolección de datos de geolocalización. En muchos casos, los datos GPS pueden ser inexactos o asignados a rutas incorrectas, lo que afecta la calidad de los análisis y la toma de decisiones. Nuestro algoritmo de map matching corrige estas deficiencias, brindando información más confiable y útil para diversas aplicaciones, desde el monitoreo de transporte hasta el análisis de tráfico.
          </Text>
        </VStack>
      </MotionBox>
    </Box>
  );
}

export default InfoAbout;

