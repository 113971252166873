import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Box, Flex, HStack, useColorModeValue, Image } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import ContactPage from './views/contact';
import Footer from './components/footer';
import LandingPage from './views/LandingPage';
import AboutUs from './views/aboutUs';
import InfoAbout from './views/InfoAbout';
import ScrollToTop from './components/ScrollToTop';

const Links = [
  { name: 'Inicio', path: '/' },
  { name: 'Nuestro Producto', path: '/Info' },
  { name: 'Nosotros', path: '/nosotros' },
  { name: 'Contáctanos', path: '/contacto' },
];

const NavLink = ({ children, path }) => {
  return (
    <Link to={path}>
      <Box
        position="relative"
        px={4}
        py={2}
        rounded={'md'}
        _hover={{
          textDecoration: 'none',
          '&::after': {
            transform: 'scaleX(1)',
            opacity: 1,
          },
        }}
        _after={{
          content: '""',
          position: 'absolute',
          left: 0,
          bottom: 0,
          width: '100%',
          height: '2px',
          backgroundColor: '#5CC166',
          transform: 'scaleX(0)',
          transition: 'transform 0.3s ease, opacity 0.3s ease',
          opacity: 0,
        }}
      >
        {children}
      </Box>
    </Link>
  );
};

function App() {
  const [showNavbar, setShowNavbar] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setShowNavbar(offset > 200);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <Router>
        <ScrollToTop /> {/* Agrega el componente ScrollToTop aquí */}
        <Box
          bg={useColorModeValue('#DDE3E9')}
          position="sticky"
          top={0}
          zIndex={10}
          px={4}
          py={2}
          boxShadow="none"
        >
          <Flex h={20} alignItems={'center'} justifyContent={'space-between'}>
            <HStack spacing={8} alignItems={'center'}>
              <Link to="/">
                <Box
                  w={{ base: '100px', md: '130px' }}
                  h={'40px'}
                  overflow="visible"
                  display="flex"
                  alignItems="center"
                >
                  <Image src="/images/logo.png" alt="Logo" layout="fill" objectFit="contain" />
                </Box>
              </Link>
            </HStack>

            <HStack as={'nav'} spacing={4} ml="auto">
              {Links.map(({ name, path }) => (
                <NavLink key={name} path={path}>
                  {name}
                </NavLink>
              ))}
            </HStack>
          </Flex>
        </Box>
        <Box mt={0}>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/Info" element={<InfoAbout />} />
            <Route path="/contacto" element={<ContactPage />} />
            <Route path="/nosotros" element={<AboutUs />} />
          </Routes>
        </Box>
      </Router>
      <Footer />
    </>
  );
}

export default App;
