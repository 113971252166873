import { Box, Grid, Text, Link, IconButton, Image, Stack, Divider, Flex, VStack, Heading, Icon, useColorModeValue } from '@chakra-ui/react';
import { FaFacebook, FaInstagram, FaLinkedinIn, FaYoutube, FaMapMarkerAlt, FaCalendarAlt } from 'react-icons/fa';

export default function Footer() {
  const handleCopyrightClick = () => {
    console.log("https://www.youtube.com/watch?v=vve1Xwu3Ge8");
  };
    return (
    <Box
      bgGradient="linear(to-b, #DDE3E9, #5CC16674)"
      py={10}
      px={6}
    >
      {/* Grid para organizar el contenido en el footer */}
      <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(5, 1fr)' }} gap={6} alignItems="center" textAlign="center">
        {/* Logo a la izquierda */}
        <Box>
          <Image src="/images/biglogo.png" alt="Logo" width="50%" mx="auto" />
        </Box>

        {/* Redes sociales */}
        <Box>
          <Text fontSize="xl" mb={4} fontWeight="bold" color="black">
            Nuestras redes sociales
          </Text>
          <Stack direction="row" justifyContent="center" spacing={4}>
            <IconButton
              as={'a'}
              href={'https://www.youtube.com/@FuturING_GeoTMM'}
              aria-label={'Youtube'}
              icon={<FaYoutube />}
              variant="ghost"
              target="_blank"
              rel="noopener noreferrer"
              _hover={{ color: '#DDE3E9' }}
            />
            <IconButton
              as={'a'}
              href={'https://www.instagram.com/geo.tmm/'}
              aria-label={'Instagram'}
              icon={<FaInstagram />}
              variant="ghost"
              target="_blank"
              rel="noopener noreferrer"
              _hover={{ color: '#DDE3E9' }}
            />
            <IconButton
              as={'a'}
              href={'https://www.linkedin.com/company/futuring-geotmm/'}
              aria-label={'LinkedIn'}
              icon={<FaLinkedinIn />}
              variant="ghost"
              target="_blank"
              rel="noopener noreferrer"
              _hover={{ color: '#DDE3E9' }}
            />
          </Stack>
        </Box>

        {/* Información de contacto */}
        <Box>
          <Text fontSize="xl" mb={4} fontWeight="bold" color="black">
            Contáctanos
          </Text>
          <Text color="black">
            Avenida Vicuña Mackenna 3939 <br />
            San Joaquín, Región Metropolitana, Chile <br />
            <strong>Email:</strong> futuring.geotmm@gmail.com
          </Text>
        </Box>

        <Box>
          <Text fontSize="xl" mb={4} fontWeight="bold" color="black">
            Feria de Software
          </Text>
          <Flex justifyContent="space-around" textAlign="center" mt="4">
            <Box>
              <Icon as={FaMapMarkerAlt} boxSize="30px" color="black" />
              <Text>Ubicación: Av. Vicuña Mackenna 3939</Text>
            </Box>
            <Box>
              <Icon as={FaCalendarAlt} boxSize="30px" color="black" />
              <Text>Fecha: 15 de Noviembre 2024</Text>
            </Box>
          </Flex>
          <Text>Para más informacion, visita: </Text>
          <Link href="https://www.feriadesoftware.cl" isExternal color="blue">
            feriadesoftware.cl
          </Link>
        </Box>
        <Box>
          <Image src="/images/logo-fesw.png" alt="Logo" width="50%" mx="auto" />
        </Box> 
      </Grid>
      {/* Divider y Derechos de autor */}
      <Text fontSize="sm"textAlign="center" color="black">
          © {new Date().getFullYear()}{' '}
          <Text
            as="span"
            onClick={handleCopyrightClick}
            _hover={{ cursor: 'pointer', textDecoration: 'none' }}
          >
            GeoTMM
          </Text>
          . Todos los derechos reservados.
        </Text>
    </Box>
  );
}

