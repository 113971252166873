// src/components/HeroSection.js
import { Box, Heading, Text, Image, Grid, GridItem } from '@chakra-ui/react';

export default function HeroSection({title, description}) {
  const highlightText = (text) => {
    // Utilizamos una expresión regular para encontrar "GeoTMM"
    const regex = /(GeoTMM)/gi;
    return text.split(regex).map((part, index) => (
      regex.test(part) ? (
        <span key={index} style={{ color: '#5CC166', fontWeight: 'bold' }}>{part}</span>
      ) : (
        part
      )
    ));
  };
  return (
    <Box
      id="hero"
      d="flex"
      alignItems="center"
      bgGradient="linear(to-b, #DDE3E9, #5CC16674)">
      
      <Grid
        templateColumns={{ base: '1fr', lg: 'repeat(12, 1fr)' }}
        gap={{ base: '4', lg: '8' }}
        alignItems="center"
        justifyContent="space-between"
      >
        {/* Texto */}
        <GridItem colSpan={{ base: 1, lg: 7 }} order={{ base: 2, lg: 1 }}>
          <Box textAlign = "center">
            <Heading as="h2" size={{ base: 'lg', md: 'xl', lg: '2xl' }} mb="4">
              {highlightText(title)}
            </Heading>
            <Text fontSize={{ base: 'md', md: 'lg' }} textAlign="center">
              {highlightText(description)}
            </Text>
          </Box>
        </GridItem>

        {/* Espaciador */}
        <GridItem colSpan={{ base: 1, lg: 1 }} />

        {/* Imagen */}
        <GridItem colSpan={{ base: 1, lg: 4 }} order={{ base: 1, lg: 3 }}>
          <Image
            src="/images/biglogo.png"
            alt="Hero Image"
            width={{ base: '100%', md: '80%', lg: '60%' }}
            height="auto"
            mb={{ base: '4', lg: '0' }}
          />
        </GridItem>
      </Grid>
    </Box>
  );
}

