import React from 'react';
import { Box, Heading, Text, Flex } from '@chakra-ui/react';
import HeroSection from '../components/HeroSection';

function AboutSection() {
  return (
    <>
    <HeroSection
        title = "Map Matching Inteligente con IA"
        description = "En Futuring, optimizamos el análisis geoespacial con GeoTMM, un algoritmo de map matching impulsado por inteligencia artificial para obtener datos más precisos y eficaces."
      />

      
    <Box id="about" py="8" px={{ base: '4', md: '8' }}>
      <Box maxW="1200px" mx="auto" >
        <Flex direction={{ base: 'column', md: 'row' }} wrap="wrap" gap="16" alignItems="center">
        {/* Video Column */}
          <Box
            flex="1"
            minW={{ base: '100%', md: '300px' }}
            mb={{ base: '4', md: '0' }}
            position="relative"
            borderRadius="md"
            overflow="hidden"
            boxShadow="md"
          >
            <Box
              as = "iframe"
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/vve1Xwu3Ge8?si=6RGTUhJDgrxY6MHL"
              title="Video de presentación"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              style={{ border: 'none' }}
            ></Box>
          </Box>
          {/* Text Column */}
          <Box flex="1" minW={{ base: '100%', md: '300px' }}>
            <ul>
              <Box as="li" mb="4" data-aos="fade-up" data-aos-delay="200">
                <Heading as="h5" size="md" color= "green">TMM</Heading>
                <Text mt="2" textAlign="justify">
                  Es la abreviatura de Topological Map Matching, una técnica que asigna puntos de datos de localización a la red vial más adecuada en función de la topología de las calles. Nuestro proyecto utiliza un algoritmo TMM de postprocesamiento, desarrollado por académicos, que mejora significativamente la precisión al mapear puntos geoespaciales en comparación con los algoritmos convencionales. Este enfoque avanzado considera factores como la velocidad y la dirección de las calles, logrando una correspondencia más exacta y confiable.
                </Text>
              </Box>
              <Box as="li" mb="4" data-aos="fade-up" data-aos-delay="100">
                <Heading as="h5" size="md">Inteligencia Artificial</Heading>
                <Text mt="2" textAlign="justify">
                  Nuestro algoritmo de postprocesamiento requiere ajustes precisos en diversos parámetros para optimizar su rendimiento. Originalmente, estos ajustes debían realizarse manualmente por los académicos que lo desarrollaron. En Futuring, hemos innovado al integrar inteligencia artificial, que actúa como un experto virtual, ajustando automáticamente los parámetros del algoritmo y evaluando las áreas clave de manera más eficiente. Esto no solo agiliza el proceso, sino que también mejora la precisión y efectividad del mapeo, reduciendo significativamente el esfuerzo humano.
                </Text>
              </Box>
              <Box as="li" mb="4" data-aos="fade-up" data-aos-delay="300">
                <Heading as="h5" size="md">Analisis de datos</Heading>
                <Text mt="2" textAlign="justify">
                  Una vez completado el proceso de Topological Map Matching (TMM), nuestro producto ofrece al usuario la capacidad de realizar un análisis detallado de los datos. Esto incluye la visualización de los recorridos de cada conductor o vehículo, el análisis del consumo de combustible, y la identificación de excesos de velocidad. Además, contamos con un algoritmo que evalúa estos comportamientos para mejorar la prevención en la conducción. Nuestro sistema genera informes automáticos y archivos exportables para un análisis externo, si así lo requiere el cliente, facilitando la toma de decisiones basadas en datos.
                </Text>
              </Box>
            </ul>
          </Box>
        </Flex>
      </Box>
    </Box>
    </>
  );
}

export default AboutSection;

