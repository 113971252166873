import React from 'react';
import { Box, Heading, Text, Link, Icon, Flex, VStack } from '@chakra-ui/react'
import { FaYoutube, FaInstagram, FaLinkedinIn, FaMapMarkerAlt, FaCalendarAlt } from 'react-icons/fa';
import HeroSection from '../components/HeroSection';
import { motion } from "framer-motion";

const MotionBox = motion(Box);

function ContactPage() {
  return (
    <Box>
       <HeroSection
        title="Contáctanos"
        description = "¿Deseas conocer más sobre GeoTMM? ¡Búscanos en la Feria de software o encuéntranos en nuestras redes sociales!"
      />
      {/* Redes Sociales */}
      <MotionBox py="40px" flex="1" p={5} initial={{ opacity: 0, y: 50 }} whileInView={{ opacity: 1, y: 0 }} transition={{ duration: 0.8 }}>
        <Flex justifyContent="center" alignItems="center" direction="column">
          <Heading as="h2" size="lg" mb="6" textAlign="center">Nuestras Redes Sociales</Heading>
          <Flex justify="space-between" align="center" width="100%" maxW="500px">
            <Link href="https://www.instagram.com/geo.tmm/" isExternal _hover={{ textDecoration: 'none' }}>
              <VStack>
                <Icon as={FaInstagram} boxSize="100px" color="#5BC67F" _hover={{ color: 'gray.400' }} />
                <Text>Instagram</Text>
              </VStack>
            </Link>
            <Link href="https://www.youtube.com/@FuturING_GeoTMM" isExternal _hover={{ textDecoration: 'none' }}>
              <VStack>
                <Icon as={FaYoutube} boxSize="100px" color="#5CC166" _hover={{ color: 'gray.400' }} />
                <Text>Youtube</Text>
              </VStack>
            </Link>
            <Link href="https://www.linkedin.com/company/futuring-geotmm/" isExternal _hover={{ textDecoration: 'none' }}>
              <VStack>
                <Icon as={FaLinkedinIn} boxSize="100px" color="#59CB98" _hover={{ color: 'gray.400' }} />
                <Text>LinkedIn</Text>
              </VStack>
            </Link>
          </Flex>
        </Flex>
      </MotionBox>


      {/* Mapa de Ubicación */}
        <MotionBox py="40px" textAlign="center" flex="1" p={5} initial={{ opacity: 0, y: 50 }} whileInView={{ opacity: 1, y: 0 }} transition={{ duration: 0.8 }}>
          <Heading as="h2" size="lg" mb="6">Ven a vernos a la XXXII Feria de software</Heading>
          <Box
            border="1px"
            borderColor="gray.300"
            borderRadius="md"
            overflow="hidden"
            boxShadow="md"
            maxWidth="800px"
            mx="auto" // Centramos horizontalmente la caja del mapa
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3327.4185450423483!2d-70.62159682340271!3d-33.490486099565274!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662d006fa56ee51%3A0x81dacfbe20db68cf!2sUniversidad%20T%C3%A9cnica%20Federico%20Santa%20Mar%C3%ADa!5e0!3m2!1ses-419!2scl!4v1691794823845!5m2!1ses-419!2scl"
              width="100%"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              title="Mapa de ubicación"
            ></iframe>
          </Box>
        </MotionBox>
          <Flex justifyContent="space-around" textAlign="center" mt="4">
            <MotionBox flex="1" p={5} initial={{ opacity: 0, y: 50 }} whileInView={{ opacity: 1, y: 0 }} transition={{ duration: 0.8 }}>
              <Icon as={FaMapMarkerAlt} boxSize="30px" />
              <Text>Ubicación: Av. Vicuña Mackenna 3939</Text>
            </MotionBox>
            <MotionBox flex="1" p={5} initial={{ opacity: 0, y: 50 }} whileInView={{ opacity: 1, y: 0 }} transition={{ duration: 0.8 }}>
              <Icon as={FaCalendarAlt} boxSize="30px" />
              <Text>Fecha: 15 de Noviembre 2024</Text>
            </MotionBox>
            <MotionBox flex="1" p={5} initial={{ opacity: 0, y: 50 }} whileInView={{ opacity: 1, y: 0 }} transition={{ duration: 0.8 }}>
              <Text>Para más informacion visita:</Text>
              <Link href="https://www.feriadesoftware.cl" isExternal color="teal.500">
                feriadesoftware.cl
              </Link>
            </MotionBox>
          </Flex>
        </Box>
  );
}

export default ContactPage;

