import { 
  Box, 
  Heading, 
  Text, 
  Image, 
  Flex, 
  SimpleGrid, 
  Avatar,
  Link,
  IconButton,
  useColorModeValue
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { FaLinkedin } from "react-icons/fa";
import HeroSection from '../components/HeroSection';

const MotionBox = motion(Box);

function AboutUs() {
  return (
    <>
      <HeroSection
        title = "¡Cononce a nuestro equipo!"
        description = ""
      />
      <Box p={8} maxW="1200px" mx="auto">
        <Flex direction={{ base: "column", md: "row" }} align="center">
          <MotionBox flex="1" p={5} initial={{ opacity: 0, y: 50 }} whileInView={{ opacity: 1, y: 0 }} transition={{ duration: 0.8 }}>
            <Heading mb={4} as="h2" fontSize="3xl">
              ¿Quiénes Somos?
            </Heading>
            <Text textAlign="justify">
              Somos un equipo comprometido con la innovación y la creación de
              soluciones tecnológicas que mejoren la vida de nuestros clientes.
              Con años de experiencia en la industria, trabajamos incansablemente
              para garantizar la excelencia en cada proyecto.
            </Text>
          </MotionBox>
          <MotionBox flex="1" p={5} initial={{ opacity: 0, y: 50 }} whileInView={{ opacity: 1, y: 0 }} transition={{ duration: 0.8 }}>
            <Image
              src="/images/Grupo.jpeg"
              alt="Imagen del equipo"
              borderRadius="md"
              boxSize="100%"
            />
          </MotionBox>
        </Flex>
        <Flex direction={{ base: "column", md: "row" }} mt={8} align="start">
          <MotionBox flex="1" p={5} initial={{ opacity: 0, y: 50 }} whileInView={{ opacity: 1, y: 0 }} transition={{ duration: 0.8 }}>
            <Heading mb={4} as="h2" fontSize="2xl">
              Visión
            </Heading>
            <Text textAlign="justify">
              Nuestra visión es ser líderes en el desarrollo de soluciones tecnológicas innovadoras que transformen la manera en que las personas interactúan con el mundo.
            </Text>
          </MotionBox>
          <MotionBox flex="1" p={5} initial={{ opacity: 0, y: 50 }} whileInView={{ opacity: 1, y: 0 }} transition={{ duration: 0.8 }}>
            <Heading mb={4} as="h2" fontSize="2xl">
              Misión
            </Heading>
            <Text textAlign="justify">
              Nuestra misión es proporcionar soluciones tecnológicas efectivas y accesibles, impulsadas por la creatividad y el compromiso con la calidad.
            </Text>
          </MotionBox>
          <MotionBox flex="1" p={5} initial={{ opacity: 0, y: 50 }} whileInView={{ opacity: 1, y: 0 }} transition={{ duration: 0.8 }}>
            <Heading mb={4} as="h2" fontSize="2xl">
              Valores
            </Heading>
            <Text textAlign="justify">
              Nuestros valores fundamentales incluyen la integridad, la innovación, la excelencia y la responsabilidad social.
            </Text>
          </MotionBox>
        </Flex>
        {/* Nueva sección: Conoce al gran equipo detrás de Geo_TMM */}
        <MotionBox mt={12} p={5} borderTop="1px" borderColor="gray.200" initial={{ opacity: 0, y: 50 }} 
              whileInView={{ opacity: 1, y: 0 }} 
              transition={{ duration: 0.8 }}>
          <Heading mb={4} as="h2" fontSize="3xl" textAlign={"center"}>
            Conoce al gran equipo detrás de Geo TMM
          </Heading>
          <Text textAlign="center" mb={8}>
            Nuestro equipo está compuesto por expertos en diversas áreas de la tecnología, todos unidos por la pasión de crear soluciones innovadoras y efectivas. Conoce más sobre cada miembro de nuestro equipo y descubre cómo su experiencia y habilidades contribuyen al éxito de Geo_TMM.
          </Text>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3}} spacing={8} justifyContent={"center"} mx="auto">
            {/* Tarjeta 1 */}
            <MotionBox
              maxW={'320px'}
              w={'full'}
              bg={useColorModeValue('white', 'gray.900')}
              boxShadow={'2xl'}
              rounded={'lg'}
              p={6}
              textAlign={'center'}
              initial={{ opacity: 0, y: 50 }} 
              whileInView={{ opacity: 1, y: 0 }} 
              transition={{ duration: 0.8 }}
              _hover={{
                transform: "translateY(-10px)",
                boxShadow: "xl",
                transition: "all 0.3s ease",
              }}>
              <Avatar
                size={'xl'}
                src={
                    "./images/Vicho.jpg"
                }
                mb={4}
                pos={'relative'}
              />
              <Heading fontSize={'2xl'} fontFamily={'body'}>
                Vicente Ruiz
              </Heading>
              <Text fontWeight={600} color={'gray.500'} mb={4}>
                Product Owner
              </Text>
              <Text
                textAlign={'center'}
                color={useColorModeValue('gray.700', 'gray.400')}
                px={3}>
                Su trabajo es encargarse de que nuestro desarrollo y trabajo tenga el mayor valor posible.
              </Text>
              {/* Icono de LinkedIn */}
              <Link href="https://www.linkedin.com/in/vicente-ruiz-22012125b/" isExternal>
                <IconButton
                  icon={<FaLinkedin boxSize={6} />} // Tamaño del icono
                  colorScheme="linkedin"
                  size="md" // Tamaño más pequeño del botón
                  p={2} // Reducir el padding
                  borderRadius="full" // Hace el botón más compacto
                  mt={4}
                />
              </Link>
            </MotionBox>
            {/* Tarjeta 2 */}
            <MotionBox
              maxW={'320px'}
              w={'full'}
              bg={useColorModeValue('white', 'gray.900')}
              boxShadow={'2xl'}
              rounded={'lg'}
              p={6}
              textAlign={'center'}
              initial={{ opacity: 0, y: 50 }} 
              whileInView={{ opacity: 1, y: 0 }} 
              transition={{ duration: 0.8 }}
              _hover={{
                transform: "translateY(-10px)",
                boxShadow: "xl",
                transition: "all 0.3s ease",
              }}>
              <Avatar
                size={'xl'}
                src={
                './images/Pipe.jpg'
                }
                mb={4}
                pos={'relative'}
              />
              <Heading fontSize={'2xl'} fontFamily={'body'}>
                Felipe Guicharrousse
              </Heading>
              <Text fontWeight={600} color={'gray.500'} mb={4}>
                Scrum Master
              </Text>
              <Text
                textAlign={'center'}
                color={useColorModeValue('gray.700', 'gray.400')}
                px={3}>
                Su trabajo es moderar y guiar a nuestro equipo para seguir una línea de organización eficiente y óptima.
              </Text>
              {/* Icono de LinkedIn */}
              <Link href="https://www.linkedin.com/in/felipe-guicharrousse/" isExternal>
                <IconButton
                  icon={<FaLinkedin boxSize={6} />} // Tamaño del icono
                  colorScheme="linkedin"
                  size="md" // Tamaño más pequeño del botón
                  p={2} // Reducir el padding
                  borderRadius="full" // Hace el botón más compacto
                  mt={4}
                />
              </Link>
            </MotionBox>
            {/* Tarjeta 3 */}
            <MotionBox
              maxW={'320px'}
              w={'full'}
              bg={useColorModeValue('white', 'gray.900')}
              boxShadow={'2xl'}
              rounded={'lg'}
              p={6}
              textAlign={'center'}
              initial={{ opacity: 0, y: 50 }} 
              whileInView={{ opacity: 1, y: 0 }} 
              transition={{ duration: 0.8 }}
              _hover={{
                transform: "translateY(-10px)",
                boxShadow: "xl",
                transition: "all 0.3s ease",
              }}>
              <Avatar
                size={'xl'}
                src={
                './images/Chrona.jpeg'
                }
                mb={4}
                pos={'relative'}
              />
              <Heading fontSize={'2xl'} fontFamily={'body'}>
                Bastian Navarrete
              </Heading>
              <Text fontWeight={600} color={'gray.500'} mb={4}>
                Marketing
              </Text>
              <Text
                textAlign={'center'}
                color={useColorModeValue('gray.700', 'gray.400')}
                px={3}>
                Su trabajo es crear información de difusión para promover el producto, cultara e imagen de nosotros.
              </Text>
              {/* Icono de LinkedIn */}
              <Link href="https://www.linkedin.com/in/felipe-guicharrousse/" isExternal>
                <IconButton
                  icon={<FaLinkedin boxSize={6} />} // Tamaño del icono
                  colorScheme="linkedin"
                  size="md" // Tamaño más pequeño del botón
                  p={2} // Reducir el padding
                  borderRadius="full" // Hace el botón más compacto
                  mt={4}
                />
              </Link>
            </MotionBox>
            {/* Tarjeta 4 */}
            <MotionBox
              maxW={'320px'}
              w={'full'}
              bg={useColorModeValue('white', 'gray.900')}
              boxShadow={'2xl'}
              rounded={'lg'}
              p={6}
              textAlign={'center'}
              initial={{ opacity: 0, y: 50 }} 
              whileInView={{ opacity: 1, y: 0 }} 
              transition={{ duration: 0.8 }}
              _hover={{
                transform: "translateY(-10px)",
                boxShadow: "xl",
                transition: "all 0.3s ease",
              }}>
              <Avatar
                size={'xl'}
                src={
                './images/Moai.jpg'
                }
                mb={4}
                pos={'relative'}
              />
              <Heading fontSize={'2xl'} fontFamily={'body'}>
                Javier Perez
              </Heading>
              <Text fontWeight={600} color={'gray.500'} mb={4}>
                Developer
              </Text>
              <Text
                textAlign={'center'}
                color={useColorModeValue('gray.700', 'gray.400')}
                px={3}>
                Su trabajo es velar por la arquitectura de software de nuestro proyecto.
              </Text>
              {/* Icono de LinkedIn */}
              <Link href="https://www.linkedin.com/in/javier-perez-pinto-0711b42bb/" isExternal>
                <IconButton
                  icon={<FaLinkedin boxSize={6} />} // Tamaño del icono
                  colorScheme="linkedin"
                  size="md" // Tamaño más pequeño del botón
                  p={2} // Reducir el padding
                  borderRadius="full" // Hace el botón más compacto
                  mt={4}
                />
              </Link>
            </MotionBox>
            {/* Tarjeta 5 */}
            <MotionBox
              maxW={'320px'}
              w={'full'}
              bg={useColorModeValue('white', 'gray.900')}
              boxShadow={'2xl'}
              rounded={'lg'}
              p={6}
              textAlign={'center'}
              initial={{ opacity: 0, y: 50 }} 
              whileInView={{ opacity: 1, y: 0 }} 
              transition={{ duration: 0.8 }}
              _hover={{
                transform: "translateY(-10px)",
                boxShadow: "xl",
                transition: "all 0.3s ease",
              }}>
              <Avatar
                size={'xl'}
                src={
                './images/Choche.jpg'
                }
                mb={4}
                pos={'relative'}
              />
              <Heading fontSize={'2xl'} fontFamily={'body'}>
                Maximiliano Tapia
              </Heading>
              <Text fontWeight={600} color={'gray.500'} mb={4}>
                Developer
              </Text>
              <Text
                textAlign={'center'}
                color={useColorModeValue('gray.700', 'gray.400')}
                px={3}>
                Su trabajo es optimizar nuestro algoritmo para que los resultados sean los mejores del mercado.
              </Text>
              {/* Icono de LinkedIn */}
              <Link href="https://www.linkedin.com/in/maxi-tapia/" isExternal>
                <IconButton
                  icon={<FaLinkedin boxSize={6} />} // Tamaño del icono
                  colorScheme="linkedin"
                  size="md" // Tamaño más pequeño del botón
                  p={2} // Reducir el padding
                  borderRadius="full" // Hace el botón más compacto
                  mt={4}
                />
              </Link>
            </MotionBox>
            {/* Tarjeta 6 */}
            <MotionBox
              maxW={'320px'}
              w={'full'}
              bg={useColorModeValue('white', 'gray.900')}
              boxShadow={'2xl'}
              rounded={'lg'}
              p={6}
              textAlign={'center'}
              initial={{ opacity: 0, y: 50 }} 
              whileInView={{ opacity: 1, y: 0 }} 
              transition={{ duration: 0.8 }}
              _hover={{
                transform: "translateY(-10px)",
                boxShadow: "xl",
                transition: "all 0.3s ease",
              }}>
              <Avatar
                size={'xl'}
                src={
                './images/Dani.jpg'
                }
                mb={4}
                pos={'relative'}
              />
              <Heading fontSize={'2xl'} fontFamily={'body'}>
                Daniel Parraguez
              </Heading>
              <Text fontWeight={600} color={'gray.500'} mb={4}>
                Developer
              </Text>
              <Text
                textAlign={'center'}
                color={useColorModeValue('gray.700', 'gray.400')}
                px={3}>
                Su trabajo es conectar el algoritmo para mostrarlo de forma visual y dinámica a nuestros usuarios.
              </Text>
              {/* Icono de LinkedIn */}
              <Link href="https://www.linkedin.com/in/daniel-parraguez-soto-b62169255/" isExternal>
                <IconButton
                  icon={<FaLinkedin boxSize={6} />} // Tamaño del icono
                  colorScheme="linkedin"
                  size="md" // Tamaño más pequeño del botón
                  p={2} // Reducir el padding
                  borderRadius="full" // Hace el botón más compacto
                  mt={4}
                />
              </Link>
            </MotionBox>
          </SimpleGrid>
        </MotionBox>
      </Box>
    </>
  );
}

export default AboutUs;


